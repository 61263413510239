import { defineStore } from "pinia";
import { type EventDate } from "~/assets/types/pollData";

export const useEventStore = defineStore("useEventStore", () => {
  const eventId = ref<string>();
  const lockedDate = ref<EventDate>();
  const signedInUserParticipantId = ref<string>();

  function $reset() {
    eventId.value = undefined;
    signedInUserParticipantId.value = undefined;
  }

  return {
    eventId: eventId,
    lockedDate: lockedDate,
    participantId: signedInUserParticipantId,
    $reset,
  };
});
